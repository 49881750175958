@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

/* Styling the main container */
.container {
    max-width: 768px;
    width: 100%;
    margin: 0 auto;
    letter-spacing: 0.5px;
    height: fit-content;
    padding-left: 16px;
    padding-right: 16px;
}

img {
    width: 30px;
    vertical-align: middle;
    border-style: none;
    border-radius: 100%;
}
/* Styling the msg-header container */
.msg-header {
    border: 1px solid #ccc;
    width: 100%;
    border-bottom: none;
    display: inline-block;
    padding: 14px 16px;
    display: flex;
    align-items: center;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    justify-content: space-between;
    background: rgb(60,187,158);
    background: linear-gradient(150deg, rgba(60,187,158,1) 0%, rgba(55,111,98,1) 100%);
}
/* Styling the profile picture */


.header-content {
    width: 100%;
    height: auto; 
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 5px;
}

.headerbtn{
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 10px;
}
.headerbtn a{
    color: #ced4da;
    font-size: 1.2rem;
}

.online{
    width: 100%;
    padding: 8px 10px;
    color: #ffffff;
    font-size: 0.675rem;
    background: #3cbb9e;
    background: linear-gradient(150deg, rgba(60,187,158,1) 0%, #3d9782 68%, #3d7769 100%);
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}

.dotgreen{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #17EBA0;
}
.online p{
    margin: 0;
}

/* styling user-name */
.active {
    width: 150px;
    color: #ffffff;
    font-weight: bold;
    margin: 0 0 0 5px;
    height: 10%;
 
}
/* Styling the inbox */
.chat-page {
    padding: 0 0 10px 0;
    background-color: #F2F2F2;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.msg-inbox {
    overflow: hidden;
}


.chats {
    padding: 30px 15px 0 25px;
}

.msg-page {
    max-height: 60vh;
    overflow-y: auto;
}

/* Styling the msg-bottom container */
 .msg-bottom {
        padding:0 16px ;
        background-color: #F2F2F2;
    }
/* Styling the input field */
    .input-group {
        margin-top: 13px;
        padding: 3px;
        position: relative;
        outline: none !important;
        background-color: #F2F2F2;
        border-radius: 12px;
        max-width: 600px;
        width: 100%;
        margin: 13px auto;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: stretch;
    }
    
    .input-group>.form-control {
        position: relative;
        height: 100%;
        margin-bottom: 0;
    }
    .input-group ul{
        list-style: none;
        padding: 8px 12px;
    }

    textarea
    {
    resize: none;
    }
    .input-group ul li{
        display: inline-block;
        margin-right: 10px;
    }
    .input-group ul li a{
        color: #777;
        font-weight: 600;
    }
    
    .form-control {
        border: none !important;
        border-radius: 10px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        width: 100%;
        display: block;
        height: calc(2.25rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }
    
    textarea:focus {
        outline: none;
        border: none !important;
        box-shadow: none !important;
    }
    
    .send-icon {
        font-size: 1.2rem;
        font-weight: 700;
        background-color: #3d7769;
        color: #ffffff;
        width: 40px;
        height: 40px;
        border-radius: 12px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding: 10px;
    }

/* Styling the avatar  */
.received-chats {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
}
.received-chats img {
    display: inline-block;
    width: 24px;
}

.received-msg {
    display: inline-block;
    vertical-align: top;
    width: 92%;
}
.received-msg-inbox {
    width: 57%;
}

.received-msg-inbox p {
    background: #efefef none repeat scroll 0 0;
    border-radius: 10px;
    color: #646464;
    font-size: 14px;
    margin-left: 1rem;
    padding: 1rem;
    width: 100%;
    box-shadow: #39373740 -3px 3px 6px 3px;
}
    p {
    overflow-wrap: break-word;
}

/* Styling the msg-sent time  */
.time {
    color: #777;
    display: block;
    font-size: 12px;
    margin: 8px 0 0;
}
.outgoing-chats {
    overflow: hidden;
    margin: 26px 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: row-reverse;
    column-gap: 10px;
}
.outgoing-msg {
    width: 57%;
}

.outgoing-chats-msg p {
    background: rgb(60,187,158);
    background: linear-gradient(150deg, rgba(60,187,158,1) 0%, rgba(55,111,98,1) 100%);
    color: #fff;
    border-radius: 10px;
    font-size: 14px;
    color: #fff;
    padding: 5px 10px 5px 12px;
    width: 100%;
    padding: 1rem;
    box-shadow: rgb(0 0 0 / 25%) 0px 2px 5px 2px;
}
.outgoing-chats-msg {
        float: right;
        width: 92%;
    }

/* Styling the avatar */
.outgoing-chats-img {
    display: inline-block;
    width: 50px;
    margin-right: 1rem;
}
@media only screen and (max-device-width: 850px) {
    .time {
        font-size: 16px;
    }
    img {
        width: 20px;
    }
    
    .msg-page {
        max-height: 350px;
        height: 100%;
        overflow-y: auto;
    }
    .received-msg-inbox p {
        font-size: 18px;
    }
    .outgoing-chats-msg p {
        font-size: 18px;
    }
}

@media only screen and (max-device-width: 500px) {
    .time {
        font-size: 18px;
    }
    img {
        width: 18px;
    }
    
    .msg-page {
        max-height: 350px;
        height: 100%;
        overflow-y: auto;
    }
    .received-msg-inbox p {
        font-size: 14px;
    }
    .outgoing-chats-msg {
        float: right;
        width: 100%;
    }
    .outgoing-chats-msg p {
        font-size: 14px;
    }
    .received-msg-inbox {
        max-width: 300px;
        width: 100%;
    }
}

/*------------- scroll bar -----------------------*/
*::-webkit-scrollbar {
    height: 10px;
    width: 5px;
  }
  *::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #DFE9EB;
  }
  
  *::-webkit-scrollbar-track:hover {
    background-color: #DFE9EB;
  }
  
  *::-webkit-scrollbar-track:active {
    background-color: #DFE9EB;
  }
  
  *::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #3BAE94;
  }
  
  *::-webkit-scrollbar-thumb:hover {
    background-color: #387F6E;
  }
  
  *::-webkit-scrollbar-thumb:active {
    background-color: #387F6E;
  }
  
  .typing-indicator {
    display: flex;
    align-items: center;
    color: gray;
  }
  
  .dot {
    animation: blink 1s infinite;
    opacity: 0;
    line-height: 0;
    font-size: 50px;
  }
  
  .dot:nth-child(1) {
    animation-delay: 0s;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes blink {
    0%, 20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  } 

